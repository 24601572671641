// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$transition-time: 0.2s;
$transition-time-fast: 0.1s;
$transition-time-slow: 0.6s;

// Sections

$default-section-padding: $spacer * 4;

// Color Scheme
//

$theme-color-scheme: ();
$theme-color-scheme: map-merge(
    (
        "primary": $primary,
        "primary-2": $primary-2,
        "primary-3": $primary-3,
    ),
    $theme-color-scheme
);

// Functions

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

$primary-escaped: str-replace(#{$primary}, "#", "%23");
$body-color-escaped: str-replace(#{$body-color}, "#", "%23");

// Shadows

$shadow-3d: 0.5rem 0.5rem 0 rgba($gray-200, 0.5);
