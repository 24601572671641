//
//
// user-fonts.scss
//
//

@import "bootstrap/scss/_type.scss";

// font faces

/* latin-ext */
@font-face {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Dosis Regular"), local("Dosis-Regular"),
        url("../assets/fonts/KAMK/Dosis-Regular-ext.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Dosis Regular"), local("Dosis-Regular"), url("../assets/fonts/KAMK/Dosis-Regular.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Raleway"), local("Raleway-Regular"),
        url("../assets/fonts/KAMK/Raleway-Regular-ext.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Raleway"), local("Raleway-Regular"), url("../assets/fonts/KAMK/Raleway-Regular.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Raleway Light"), local("Raleway-Light"),
        url("../assets/fonts/jumppakamu/Raleway-Light-ext.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local("Raleway Light"), local("Raleway-Light"),
        url("../assets/fonts/jumppakamu/Raleway-Light.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Open Sans"), local("OpenSans-Regular-ext"),
        url("../assets/fonts/KAMK/OpenSans-Regular-ext.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Open Sans"), local("OpenSans-Regular"),
        url("../assets/fonts/KAMK/OpenSans-Regular.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Impact";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Impact"), url("../assets/fonts/KAMK/unicode.impact.ttf") format("ttf");
}

@font-face {
    font-family: "DIN Condensed";
    font-style: bold;
    font-weight: 400;
    font-display: swap;
    src: local("DIN Condensed"), url("../assets/fonts/KAMK/D-DINCondensed-Bold.woff2") format("woff2");
}

/* latin */
@font-face {
    font-family: "Saira Condensed";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Saira Condensed Regular"), local("SairaCondensed-Regular"),
        url("../assets/fonts/KAMK/SairaCondensed-Regular.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: "Saira Condensed";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Saira Condensed Bold"), local("SairaCondensed-Bold"),
        url("../assets/fonts/KAMK/SairaCondensed-Bold.ttf") format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* pt-sans-regular - latin */
@font-face {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.eot"); /* IE9 Compat Modes */
    src: local("PT Sans"), local("PTSans-Regular"),
        url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-regular.svg#PTSans")
            format("svg"); /* Legacy iOS */
}
/* pt-sans-700 - latin */
@font-face {
    font-family: "PT Sans";
    font-style: normal;
    font-weight: 700;
    src: url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.eot"); /* IE9 Compat Modes */
    src: local("PT Sans Bold"), local("PTSans-Bold"),
        url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("../assets/fonts/KAMK/pt-sans/pt-sans-v11-latin-700.svg#PTSans") format("svg"); /* Legacy iOS */
}

/* Roboto */
/* tämä ladataan firebaseui:ssa joka tapuksessa täältä ja prod ylikirjautuu */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");

// Benton Sans
@font-face {
    font-family: "BentonSans";
    src: local("BentonSans"), url("../assets/fonts/Edukamu/BentonSans.woff2") format("woff2"),
        url("../assets/fonts/Edukamu/BentonSans.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "BentonSans";
    src: local("BentonSans Black"), local("BentonSans-Black"),
        url("../assets/fonts/Edukamu/BentonSans-Black.woff2") format("woff2"),
        url("../assets/fonts/Edukamu/BentonSans-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "BentonSans";
    src: local("BentonSans Bold"), local("BentonSans-Bold"),
        url("../assets/fonts/Edukamu/BentonSans-Bold.woff2") format("woff2"),
        url("../assets/fonts/Edukamu/BentonSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Cabin";
    src: local("Cabin Bold"), local("Cabin-Bold"), url("../assets/fonts/jumppakamu/Cabin-Bold.woff2") format("woff2"),
        url("../assets/fonts/jumppakamu/Cabin-Bold.ttf") format("ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Calluna Regular";
    src: local("Calluna Regular"), local("Calluna-Regular"),
        url("../assets/fonts/jumppakamu/Calluna-Regular.woff2") format("woff2"),
        url("../assets/fonts/jumppakamu/Calluna-Regular.woff") format("woff"),
        url("../assets/fonts/jumppakamu/Calluna-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}

// /font faces

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: "Open Sans";
}

h5,
.h5 {
    line-height: 1.875rem;
}

@include media-breakpoint-down(lg) {
    html {
        font-size: 0.9375rem;
    }
}

@include media-breakpoint-down(sm) {
    html {
        font-size: 0.875rem;
    }
}

@include media-breakpoint-down(md) {
    .display-2 {
        font-size: $display4-size;
    }
}

.display-1,
.display-2,
.display-3,
.display-4 {
    color: $headings-color;
}

.display-1,
.display-2 {
    line-height: 1;
}

.display-3 {
    line-height: 4.5rem;
}

.display-4 {
    line-height: 4rem;
}

@include media-breakpoint-down(lg) {
    .display-3 {
        font-size: $display4-size;
        line-height: 4rem;
    }
}

@include media-breakpoint-down(sm) {
    .display-4 {
        font-size: $h1-font-size;
        line-height: 3rem;
    }
}

h5 {
    margin-bottom: $spacer/2;
}

h6 {
    line-height: 1.375rem;
}

a {
    font-weight: 500;
    &.lead {
        font-weight: 500;
    }
    color: theme-color("primary");
}

small,
.text-small {
    font-weight: 500;
}

.text-small {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

small {
    display: inline-block;
    line-height: 1.1875rem;
    &.text-uppercase {
        font-weight: 700;
    }
}

strong {
    font-weight: $font-weight-bold;
}

.text-light {
    a:not(.btn),
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p,
    .p,
    .display-1,
    .display-2,
    .display-3,
    .display-4 {
        color: $white;
        font-family: Dosis;
    }
    .text-muted {
        color: rgba($white, 0.7) !important;
    }
}

.highlight {
    display: inline;
    background: rgba(theme-color("primary-2"), 0.2);
}

.alert {
    word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: Dosis;
}
