.khub_logo_white {
    width: auto;
    height: 1.5rem;
    padding-right: 50px;

    g {
        .khub_logo_k {
            fill: #009aa6;
            transition: fill $transition-time-slow ease;
        }

        g {
            .khub_logo_text {
                fill: white;
            }
        }
    }
}

.khub_logo_white_k_white {
    width: auto;
    height: 1.5rem;
    padding-right: 50px;

    g {
        .khub_logo_k {
            fill: white;
            transition: fill $transition-time-slow ease;
        }

        g {
            .khub_logo_text {
                fill: white;
            }
        }
    }
}

.khub_logo_white_k_white_large {
    width: auto;
    height: 2.5rem;
    padding-right: 50px;

    g {
        .khub_logo_k {
            fill: white;
            transition: fill $transition-time-slow ease;
        }

        g {
            .khub_logo_text {
                fill: white;
            }
        }
    }
}

.khub_logo_black {
    width: auto;
    height: 1.5rem;
    padding-right: 50px;

    g {
        .khub_logo_k {
            fill: #009aa6;
            transition: fill $transition-time-slow ease;
        }

        g {
            .khub_logo_text {
                fill: black;
            }
        }
    }
}

.istoc-logo {
    width: auto;
    height: 2.2rem;

    g {
        g {
            .logo-path {
                fill: rgb(152, 152, 156);
            }

            .logo-path2 {
                fill: rgb(17, 43, 80);
            }
        }
    }
}

.kauppakamari-logo {
    width: auto;
    height: 2.2rem;
}

.koulutusonline-logo {
    width: auto;
    height: 2.2rem;
    g {
        transition: fill $transition-time-slow ease;
    }
}

.edukamu-logo {
    color: $primary-6;
    margin: 0.4rem 5px 0 5px;
    font-size: 1.4rem;
    font-family: "Roboto" !important;
    transition: color $transition-time-slow ease;
}

.khub_logo_k_on_white {
    width: auto;
    height: 3.5rem;

    g {
        .khub_logo_k_on_w {
            fill: $primary-6;
            transition: fill $transition-time-slow ease;
        }

        g {
            .khub_logo_text {
                fill: black;
            }
        }
    }
}

.avoimet_nav_logo {
    g {
        .avoimet_door_frame {
            fill: white;
            transition: fill $transition-time-slow ease;
        }

        .avoimet_door {
            fill: white;
            transition: fill $transition-time-slow ease;
            transition: d 0.5s;
            d: path(
                "m7.8139 3.3661 10.364 0.025884c0.48351 0.0012075 0.8217 0.50047 0.8217 0.98398v15.401c0 0.48351-0.33819 0.98396-0.8217 0.98398l-10.364 4.6e-4c-0.55228 2.5e-5 -1.0638-0.26224-1.1623-0.80568-0.010661-0.05883-0.016024-0.11851-0.016024-0.1783v-15.427c0-0.55228 0.44772-1 1-1 0.059792 0 0.11851 0.015874 0.1783 0.016023zm1.7384 9.5399c0.69036 0 1.25-0.60845 1.25-1.359 0-0.75056-0.55964-1.359-1.25-1.359-0.69036 0-1.25 0.60845-1.25 1.359 0 0.75056 0.55964 1.359 1.25 1.359z"
            );
        }
    }
}

.scrolled {
    .avoimet_nav_logo {
        g {
            .avoimet_door {
                d: path(
                    "m10.178 2.2474 8 1.4496c0.47576 0.086209 0.8217 0.50047 0.8217 0.98398v14.638c0 0.48351-0.34594 0.89777-0.8217 0.98398l-8 1.4496c-0.54344 0.098471-1.0638-0.26224-1.1623-0.80568-0.010661-0.058834-0.016024-0.11851-0.016024-0.1783v-17.537c0-0.55228 0.44772-1 1-1 0.059792 0 0.11946 0.0053626 0.1783 0.016023zm1.7384 10.659c0.69036 0 1.25-0.60845 1.25-1.359 0-0.75056-0.55964-1.359-1.25-1.359-0.69036 0-1.25 0.60845-1.25 1.359 0 0.75056 0.55964 1.359 1.25 1.359z"
                );
            }
        }
    }

    .khub_logo_white {
        g {
            .khub_logo_k {
                fill: white;
                transition: fill $transition-time-slow ease;
            }
        }
    }

    .edukamu-logo {
        color: white;
        transition: color $transition-time-slow ease;
    }

    .koulutusonline-logo {
        g {
            fill: white;
            transition: fill $transition-time-slow ease;
        }
    }
}
