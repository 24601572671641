//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import "user-components/quiz";
@import "user-components/logo";
@import "user-components/card";
@import "user-components/loader-custom";
@import "signin.scss";
@import "user-fonts.scss";

body {
    overflow-x: hidden !important;
}

/* form field is required */
.form-label.required:after {
    content: "*";
    color: red;
}
/* form field is invalid */
.c-is-invalid {
    border: 1px solid red;
}

.img-with-margin {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.iSTOC_Table {
    th {
        font-weight: bold;
    }

    .no_border_top_bot {
        td {
            border-top: none;
            border-bottom: none;
        }

        th {
            border-top: none;
            border-bottom: none;
        }
    }
    .no_border_top {
        td {
            border-top: none;
        }

        th {
            border-top: none;
        }
    }

    .no_border_bot {
        td {
            border-bottom: none;
        }

        th {
            border-bottom: none;
        }
    }

    .deli_sub {
        td {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
        }

        th {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            padding-left: 20px;
        }

        .location_class {
            width: 7em;
        }
        .location_lab {
            width: 7em;
        }
        .location_online {
            width: 7em;
        }
    }

    .deli_head {
        td {
            padding-bottom: 0.25rem;
        }
        th {
            padding-bottom: 0.25rem;
            font-weight: bold;
        }
    }

    tr {
        .location_class {
            //color: rgb(198, 224, 180);
            color: rgb(130, 183, 94);
            font-weight: bold;
        }

        .location_online {
            //color: rgb(255, 255, 100);
            color: rgb(255, 193, 7);
            font-weight: bold;
        }

        .location_lab {
            //color: rgb(255, 230, 153);
            color: rgb(255, 87, 34);
            font-weight: bold;
        }

        .location_field {
            //color: rgb(189, 215, 238);
            color: rgb(150, 191, 228);
            font-weight: bold;
        }

        .provider_inst {
            color: $primary;
            font-weight: bold;
        }

        .provider_kamk {
            //color: rgb(84, 181, 189);
            color: $primary-6;
            //color: white;
            font-weight: bold;
        }

        .provider_istoc {
            color: rgb(79, 109, 165);
            //color: white;
            font-weight: bold;
        }

        .day_exam {
            color: rgb(248, 203, 173);
            font-weight: bold;
        }

        .Hours_total {
            color: rgb(84, 130, 53);
        }
    }
}

.navKHublink:hover {
    text-decoration: unset;
}

@media only screen and (max-width: 600px) {
    .avoimetOvet {
        svg {
            height: 3rem !important;
        }

        h1 {
            font-size: 2.5rem !important;
        }
    }

    .avoimetKHUB {
        h1 {
            margin: -0.4rem 2px 0 2px !important;
            font-size: 1.8rem !important;
        }
        svg {
            height: 1.25rem;
        }
    }
}
/*
@media (max-width: 992px) {
    .customDropdown {
        .dropdown {
            .navDropdownButtonOwn {
                span {
                    opacity: 0 !important;
                    width: 0 !important;
                }
                svg {
                    opacity: 1 !important;
                    margin-right: 1rem;
                }
            }
        }
        .dropdown.show {
            .navDropdownButtonOwn {
                span {
                    opacity: 0 !important;
                    width: 0 !important;
                }
                svg {
                    opacity: 1 !important;
                    margin-right: 1rem;
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    .customDropdown {
        .dropdown {
            .dropdown-menu.dropdown-content {
                right: -1rem !important;
            }
            .piilotettu {
                display: block !important; 
            }
        }
        .hideCourse {
            display: none !important;
        }
        .istochiddenropdown {
            display: inline-block !important;
        }
    }

    .avoimetDropdown.customDropdown {
        display: block !important;
    }
    .avoimetKirjaudu {
        display: none !important;
    }
}
*/

@media (max-width: 769.98px) {
    .navbar_between {
        display: none !important;
    }

    div.navbar_below {
        display: unset !important;
    }
}
@media (min-width: 770px) {
    .navbar-container {
        .navbar.navbar-expand-lg {
            div.navbar_below {
                display: none !important;
            }
        }
    }
}

.customDropdown {
    .dropdown {
        .navDropdownButtonOwn {
            span {
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
                    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5;
                color: rgba(255, 255, 255, 0.5);
                transition: 0.2s;
            }
            svg {
                * {
                    transition: 0.5s;
                }
                .NavButtonVector {
                    .Yla {
                        transform: matrix(1.0073, 0, 0, 0.93902, -0.13571, 8.0595);
                    }
                    .Keski {
                        transform: matrix(1.0073, 0, 0, 0.93902, -0.13571, 8.8662);
                    }
                    .Ala {
                        transform: matrix(1.0073, 0, 0, 0.93902, -0.13571, 9.6727);
                    }
                }
            }
        }
    }
    .dropdown.show {
        .navDropdownButtonOwn {
            span {
                color: white;
            }
            svg {
                * {
                    transition: 0.5s;
                }
                .NavButtonVector {
                    .Yla {
                        transform: matrix(0.8723712, -0.50366375, 0.46951201, 0.81321866, -46.892308, 91.523813);
                    }
                    .Keski {
                        transform: matrix(1.0073275, 0, 0, 0.93902402, 138.84444, 8.8661517);
                        opacity: 0;
                    }
                    .Ala {
                        transform: matrix(0.8723712, 0.50366375, -0.46951231, 0.81321918, 95.172867, -35.725374);
                    }
                }
            }
        }
    }
}

// Backgrounds
.bg-primary {
    background-color: $primary !important;
    border-color: $primary !important;
}

.bg-primary-2 {
    background-color: $primary-2 !important;
    border-color: $primary-2 !important;
}

.bg-primary-3 {
    background-color: $primary-3 !important;
    border-color: $primary-3 !important;
}

.bg-primary-4 {
    background-color: $primary-4 !important;
    border-color: $primary-4 !important;
}

.bg-primary-5 {
    background-color: $primary-5 !important;
    border-color: $primary-5 !important;
}

.bg-primary-6 {
    background-color: $primary-6 !important;
    border-color: $primary-6 !important;
}

.bg-kauppakamari {
    background-color: $kauppakamari !important;
    border-color: $kauppakamari !important;
}

.bg-kauppakamari-2 {
    background-color: $kauppakamari-2 !important;
    border-color: $kauppakamari-2 !important;
}

.bg-kauppakamari-3 {
    background-color: $kauppakamari-3 !important;
    border-color: $kauppakamari-3 !important;
}

.bg-kauppakamari-4 {
    background-color: $kauppakamari-4 !important;
    border-color: $kauppakamari-4 !important;
}

.bg-kauppakamari-5 {
    background-color: $kauppakamari-5 !important;
    border-color: $kauppakamari-5 !important;
}

.bg-kauppakamari-6 {
    background-color: $kauppakamari-6 !important;
    border-color: $kauppakamari-6 !important;
}

.bg-edukamu {
    background-color: $edukamu !important;
    border-color: $edukamu !important;
}

.bg-edukamu-2 {
    background-color: $edukamu-2 !important;
    border-color: $edukamu-2 !important;
}

.bg-edukamu-3 {
    background-color: $edukamu-3 !important;
    border-color: $edukamu-3 !important;
}

.bg-edukamu-4 {
    background-color: $edukamu-4 !important;
    border-color: $edukamu-4 !important;
}

.bg-edukamu-5 {
    background-color: $edukamu-5 !important;
    border-color: $edukamu-5 !important;
}

.bg-jumppakamu {
    background-color: $jumppakamu !important;
    border-color: $jumppakamu !important;
}

.bg-jumppakamu-1 {
    background-color: $jumppakamu-1 !important;
    border-color: $jumppakamu-1 !important;
}

.bg-jumppakamu-2 {
    background-color: $jumppakamu-2 !important;
    border-color: $jumppakamu-2 !important;
}

.bg-lightgrey {
    background-color: $lightgrey !important;
    border-color: $lightgrey !important;
}

.bg_custom_primary {
    background-color: $primary-4;
}

// Texts
.text-primary {
    color: $primary !important;
}

.text-primary-2 {
    color: $primary-2 !important;
}

.text-primary-3 {
    color: $primary-3 !important;
}

.text-primary-4 {
    color: $primary-4 !important;
}

.text-primary-5 {
    color: $primary-5 !important;
}

.text-primary-6 {
    color: $primary-6 !important;
}

.text-kauppakamari {
    color: $kauppakamari !important;
}

.text-kauppakamari-2 {
    color: $kauppakamari-2 !important;
}

.text-kauppakamari-3 {
    color: $kauppakamari-3 !important;
}

.text-kauppakamari-4 {
    color: $kauppakamari-4 !important;
}

.text-kauppakamari-5 {
    color: $kauppakamari-5 !important;
}

.text-kauppakamari-6 {
    color: $kauppakamari-6 !important;
}

.text-edukamu {
    color: $edukamu !important;
}

.text-edukamu-1 {
    color: $edukamu-1 !important;
}

.text-edukamu-2 {
    color: $edukamu-2 !important;
}

.text-edukamu-3 {
    color: $edukamu-3 !important;
}

.text-edukamu-4 {
    color: $edukamu-4 !important;
}

.text-edukamu-5 {
    color: $edukamu-5 !important;
}

.text-digitaidot {
    color: $digitaidot !important;
}

.text-jumppakamu {
    color: $jumppakamu !important;
}

.text-jumppakamu-1 {
    color: $jumppakamu-1 !important;
}

.text-jumppakamu-2 {
    color: $jumppakamu-2 !important;
}

// SVG
.svg-primary * {
    fill: $primary !important;
}

.svg-primary-2 * {
    fill: $primary-2 !important;
}

.svg-primary-3 * {
    fill: $primary-3 !important;
}

.svg-primary-4 * {
    fill: $primary-4 !important;
}

.svg-primary-5 * {
    fill: $primary-5 !important;
}

.svg-primary-6 * {
    fill: $primary-6 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari * {
    fill: $kauppakamari !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari-2 * {
    fill: $kauppakamari-2 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari-3 * {
    fill: $kauppakamari-3 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari-4 * {
    fill: $kauppakamari-4 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari-5 * {
    fill: $kauppakamari-5 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-kauppakamari-6 * {
    fill: $kauppakamari-6 !important;
    transition: all $transition-time-slow ease !important;
}

.svg-edukamu * {
    fill: $edukamu !important;
}

.scrolled {
    .svg-primary-6 * {
        fill: white !important;
        transition: all $transition-time-slow ease !important;
    }

    .svg-kauppakamari-6 * {
        fill: white !important;
        transition: all $transition-time-slow ease !important;
    }
}

// Hover dropdown
/* Show the dropdown menu on hover */
.hover-dropdown:hover .dropdown-menu {
    display: table;
}

.hover-dropdown .dropdown-menu {
    min-width: 0;
    left: 32px;
    border: 0;
    padding: 0;
    margin: 0;
}

.hover-dropdown .dropdown-menu li {
    font-size: 0.875rem;
    padding: 0 4px;
}

.hpc_cover_slide_img {
    object-fit: cover;
    object-position: 50% 40%;
    max-width: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

#carousel-section {
    min-height: 59vh;
}

@media only screen and (max-height: 520px) {
    /*
    .carousel-inner {
      display: none;
    }
    .carousel-control-prev {
      display: none;
    }
    .carousel-control-next {
      display: none;
    }
    .carousel-indicators {
      display: none;
    }
  
    #carousel-section {
      min-height: 12vh;
    }
    */

    .navbar {
        background: #009aa6;
    }

    #carousel-section {
        display: none;
    }
}

.carousel-item {
    .custom_pos {
        bottom: unset;
        top: 17.5%;

        h1 {
            font-size: 5.5rem;
        }
        p {
            font-size: 1.25rem;
        }
    }
}

.test_text_shadow {
    text-shadow: 0px 0px 10px #3e3e3e;
}

// buttons
.btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
}

.btn-edukamu-3 {
    background-color: $edukamu-3 !important;
    border-color: $edukamu-3 !important;

    * {
        fill: white !important;
    }
}

.Testi_Dashed {
    border: 6px dashed $primary-5;
}

.avoimet_article {
    li {
        color: black !important;
    }
}

.custom_Article {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary-6;
        margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2rem;
    }

    h4 {
        color: $primary-6;
        margin-bottom: 1rem;
    }

    h5 {
        color: $primary-6;
    }

    p + {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 2rem;
        }
    }
    p:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    hr {
        margin-top: 0.5rem;
        border-top-color: rgba($primary-4, 0.8);
    }

    a {
        font-weight: 400;
        color: $primary;
    }

    li {
        color: $primary-5;
        span {
            color: #212529;
        }
        ul {
            li:first-child {
                margin-top: 0.5rem;
            }
        }
    }

    .group_violetti {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($primary-3, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }

    .group_vihrea {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($primary-5, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }

    .group_pinkki {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($primary-2, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }
    .group_oranssi {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($primary-4, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }

        /*h4 {
            margin-bottom: 0.5rem;
        }*/
    }

    svg {
        g {
            path {
                fill: $primary-6 !important;
            }
        }
    }
}

.kauppakamari-article {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $kauppakamari-6;
        margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 2rem;
    }

    h4 {
        color: $kauppakamari-6;
        margin-bottom: 1rem;
    }

    h5 {
        color: $kauppakamari-6;
    }

    p + {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 2rem;
        }
    }
    p:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    hr {
        margin-top: 0.5rem;
        border-top-color: rgba($kauppakamari-4, 0.8);
    }

    a {
        font-weight: 400;
        color: $kauppakamari;
    }

    li {
        span {
            color: #212529;
        }
        ul {
            li:first-child {
                margin-top: 0.5rem;
            }
        }
    }

    .group_violetti {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($kauppakamari-3, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }

    .group_vihrea {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($kauppakamari-5, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }

    .group_pinkki {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($kauppakamari-2, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }
    }
    .group_oranssi {
        background-color: #efefef;
        border-radius: 4px;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-left: 5px solid rgba($kauppakamari-4, 0.8);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0;
        }

        /*h4 {
            margin-bottom: 0.5rem;
        }*/
    }

    svg {
        g {
            path {
                fill: $kauppakamari-6 !important;
            }
        }
    }
}

#chartjs-tooltip {
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 3px;
    transition: all 0.1s ease;
    pointer-events: none;
    transform: translate(-50%, 0);
    z-index: 1000;

    th {
        color: white;
        font-weight: bold;
    }

    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }
}

.firebaseOmatTiedot {
    .tallennaButton {
        width: 10rem;
        margin: auto;
        padding: 0.4rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

/**
 * Complete napin tyyli hienosäädöt
  */
#complete-exam-button {
    margin-bottom: 20px;
    div {
        padding: 10px;
        min-height: auto !important;
        h2 {
            margin: 0;
        }
    }
}

// saattohoito table korjaukset
article {
    table {
        table-layout: fixed;
        width: 100%;
        td {
            white-space: normal !important;
            word-break: break-word;
        }
    }
}

// dropdown valikon näkyvyys muiden elementtien päällä
.multi-select {
    z-index: 10;
}

.digitaidot-kyna {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
        transform: rotate(-45deg) scale(1.1);
    }
}

.digitaidot-leipateksti {
    font-size: 1.5rem;
}

.digitaidot-leipateksti-tentti {
    font-size: 1.3rem;
}
