//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color Scheme
// Uncomment the variables below and change the hex values.

$primary: rgb(0, 178, 227); // KAMK sininen
$primary-2: rgb(214, 0, 109); // KAMK Pinkki
$primary-3: rgb(97, 33, 102); // KAMK violetti
$primary-4: rgb(238, 118, 35); // KAMK oranssi
$primary-5: rgb(128, 188, 0); // KAMK vihreä
$primary-6: rgb(0, 154, 166); // KAMK Petrooli

// Kauppakamari
$kauppakamari: #2196f3; // Kauppakamari Blue: Button color
$kauppakamari-2: #ff97ca; // Kauppakamari Pink: Text highlight
$kauppakamari-3: rgb(97, 33, 102); // KAMK violetti
$kauppakamari-4: #ffc62f; // Kauppakamari Light yellow: Graphics highlight
$kauppakamari-5: #fff4fa; // Kauppakamari Light Pink: Background color
$kauppakamari-6: #002663; // Kauppakamari Dark Blue: Kauppakamari logo blue and text headers

// Edukamu
$edukamu: #000000;
$edukamu-1: #ffffff;
$edukamu-2: #e4008a;
$edukamu-3: #3688fd;
$edukamu-4: #e8ebf3;
$edukamu-5: #6c4bd5;
$edukamu-6: #f3f3f3;

$digitaidot: #3320a3;

// Jumppakamu
$jumppakamu: #3e3f41;
$jumppakamu-1: $edukamu-3;
$jumppakamu-2: $edukamu-2;

$paihteet: #f5e3d4;
$paihteet-2: #c6bab9;
$paihteet-3: #2b2b2b;
$paihteet-4: #808080;

$lightgrey: rgb(240, 240, 240);

// navbar
$navbar-brand-padding-y: 0;

// fonts
$font-family-sans-serif: "Dosis";
$font-family-monospace: "Raleway";
$font-family-base: $font-family-sans-serif;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary": $primary,
    "primary-2": $primary-2,
    "primary-3": $primary-3,
    "primary-4": $primary-4,
    "primary-5": $primary-5,
    "primary-6": $primary-6,
);

$theme-colors: (
    "primary": $primary,
    "primary-2": $primary-2,
    "primary-3": $primary-3,
    "primary-4": $primary-4,
    "primary-5": $primary-5,
    "primary-6": $primary-6,
);
