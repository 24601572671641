/* Typing Animation */
$dot-size: 24px;
$dot-offset: 40px;
$dot-growth: 4px;
$dot-bob: -8px;
$dot-color: rgba(255, 255, 255, 1);
$bob-color: rgba(255, 255, 255, 0.6);

.loading-animation-digitaidot {
    width: $dot-size;
    height: $dot-size;
    border-color: transparent;
    border-radius: 50%;
    animation: typing-digitaidot 2s ease-in-out infinite normal;
    position: relative;
    left: -$dot-offset * 2;
    background-color: transparent;
}

@keyframes typing-digitaidot {
    0% {
        box-shadow: $dot-offset $dot-bob 0px $dot-growth $bob-color,
            $dot-offset * 2 0px 0px 0px $dot-color,
            $dot-offset * 3 0px 0px 0px $dot-color;
    }

    25% {
        box-shadow: $dot-offset 0px 0px 0px $dot-color,
            $dot-offset * 2 $dot-bob 0px $dot-growth $bob-color,
            $dot-offset * 3 0px 0px 0px $dot-color;
    }

    50% {
        box-shadow: $dot-offset 0px 0px 0px $dot-color,
            $dot-offset * 2 0px 0px 0px $dot-color,
            $dot-offset * 3 $dot-bob 0px $dot-growth $bob-color;
    }

    75% {
        box-shadow: $dot-offset 0px 0px 0px $dot-color,
            $dot-offset * 2 $dot-bob 0px $dot-growth $bob-color,
            $dot-offset * 3 0px 0px 0px $dot-color;
    }

    100% {
        box-shadow: $dot-offset $dot-bob 0px $dot-growth $bob-color,
            $dot-offset * 2 0px 0px 0px $dot-color,
            $dot-offset * 3 0px 0px 0px $dot-color;
    }
}
