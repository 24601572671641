.firebaseui-auth-modal .modal-content {
    background-color: transparent;
    border: none;
}

.firebaseui-title {
    color: #009aa6 !important;
}

.mdl-button.mdl-button--colored {
    color: #009aa6 !important;
}

.mdl-button--raised.mdl-button--colored {
    background: #009aa6 !important;
    color: #fff !important;
}

.mdl-progress > .progressbar {
    background-color: #5f7577 !important;
}

.mdl-progress > .bufferbar {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
        linear-gradient(to right, #009aa6, #009aa6) !important;
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
    color: #009aa6 !important;
}
.mdl-textfield__label:after {
    background-color: #009aa6 !important;
}
