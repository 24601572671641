// 
// 
// navbar.scss
//
//

.navbar{
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: $border-width solid rgba($primary,.08);
  width: 100%;
  &.navbar-edukamu4{
    background: rgba(0,0,0,0);
    border-bottom: 0;
    padding: 0;
  }
  &.navbar-white{
    background: rgba(255,255,255,1);
    border-bottom: 0;
    padding: 0;
  }
  &.navbar-fb{
    background: #fbfbfb;
    border-bottom: 0;
    padding: 0;
  }
  &[data-overlay]{
    position: absolute;
  }
  &[data-sticky="top"]{
    transition: all $transition-time-slow ease;
    &.scrolled{
      &.navbar-dark{
        background: theme-color('primary');
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-dark-kauppakamari{
        background: $kauppakamari-6;
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-primary6{
        background: theme-color('primary-6');
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-edukamu3{
        background: $edukamu-3;
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-jumppakamu{
        background: $jumppakamu;
        border-bottom-color: rgba(0,0,0,0);
      }
      // &.navbar-edukamu4{
      //   background: $edukamu;
      //   border-bottom-color: rgba(0,0,0,0);
      //   *.edukamu-logo-black {
      //     display: none
      //   }
      //   *.edukamu-logo-white {
      //     display: block !important;
      //   }
      // }
      &.navbar-light{
        background: $white !important;
      }
    }
  }
  &[data-overlay],&[data-sticky="top"]{
    z-index: (($zindex-dropdown)-1);
    &.scrolled{
      z-index: $zindex-fixed;
    }
  }
  .dropdown-menu{
    z-index: $zindex-popover;
  }
  .nav-link{
    transition: color $transition-time-slow ease;
  }
  .navbar-brand{
    max-height: 0;
    img{
      max-height: 8rem;
      width: auto;
    }
  }
}

.navbar-container{
  position: relative;
}

.navbar-light{
  .navbar-toggler{
    svg{
      path{
        fill: theme-color('dark');
      }
    }
  }
}

.navbar-dark{
  border-bottom: $border-width solid rgba($white,.08);
  .navbar-toggler{
    svg{
      path{
        fill: $white;
      }
    }
  }
}

.navbar-dark-kauppakamari{
  border-bottom: $border-width solid rgba($white,.08);
  .navbar-toggler{
    svg{
      path{
        fill: $white;
      }
    }
  }
}

.navbar-toggler{
  border: none;
  position: relative;
  &[aria-expanded="false"]{
    .navbar-toggler-close{
      opacity: 0;
      transform: scale(0) rotate(45deg);
    }
  }
  &[aria-expanded="true"]{
    .navbar-toggler-open{
      opacity: 0;
      transform: scale(0) rotate(-45deg);
    }
  }
}

.navbar-toggler-open, .navbar-toggler-close{
  transition: opacity $transition-time-slow ease, transform $transition-time-slow ease;
  opacity: 1;
  transform: scale(1);
}

.navbar-toggler-close{
  position: absolute;
  top: 0;
  left: 0;
}

@include media-breakpoint-up(lg){
  .navbar{
    padding-left: 0;
    padding-right: 0;
    .dropdown-menu{
      margin-top: 1rem;
    }
  }
}

nav[data-overlay].navbar-toggled-show{
  background: $white;
  &.navbar-dark{
    background: theme-color('primary-3');
  }
  &.navbar-dark-kauppakamari{
    background: $kauppakamari-3;
  }
}

@include media-breakpoint-down(md){
  .navbar-collapse{
    max-height: 100vh;
    overflow-y: scroll;
  }
}

// Navbar Dropdowns

@include media-breakpoint-down(md){
  .nav-item.dropdown{
    .col-auto{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}