@import "../user-variables.scss";

.card {
    margin-bottom: auto !important;
    border-radius: 0;
}

.card-img-top {
    border-radius: 0;
}

.card-list-entry {
    transition: background-color 0.5s ease;
    background-color: unset;
}

.card-list-entry:hover {
    transition: background-color 0.5s ease;
    background-color: rgb(230, 230, 230);
}

.progress_container {
    .progress {
        .progress-bar.completed {
            background-color: rgb(128, 188, 0) !important;
        }
    }
}

.avoimet_custom_card {
    width: 100%;

    h4 {
        margin-bottom: 0.5rem;
    }

    ul {
        margin-bottom: 0.5rem;
    }

    a {
        overflow: hidden;

        img {
            background-position: center center;
            background-color: #ccc;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    svg {
        max-width: 100%;

        * {
            transition: 1s;
        }

        .module_1 {
            .module_1_student {
                transform: matrix(1.5021, 0, 0, 1.5021, -2157.1, -238.66);
            }

            .module_1_school {
                transform: matrix(1.0838, 0, 0, 1.0838, -2251.2, -227.03);
            }
        }

        .module_2 {
            .Person_sitting {
                transform: matrix(-1.1525, 0, 0, 1.1525, 1788.3792, -542.3807);
            }

            .Person_computer {
                transform: matrix(1.2968029, 0, 0, 1.2968029, -2190.3426, -540.13098);
            }
            .Floor {
                transform: matrix(1, 0, 0, 1, -25.022991, 4.4272115);
            }
        }

        .module_3 {
            .person_1 {
                transform: matrix(1.1912, 0, 0, 1.1912, -1832.5, -427.83);
            }
            .person_2 {
                transform: matrix(-1.0546, 0, 0, 1.0546, 1836, -207.95);
            }
            .module_1_student {
                transform: matrix(1.0005, 0, 0, 1.0005, -1259.5, -45.603);
            }
            .person_4 {
                transform: matrix(1.078, 0, 0, 1.078, -1670.8, -161.46);
            }
            .person_5 {
                transform: matrix(0.98616, 0, 0, 0.98616, -1479.3, 118.64);
            }
            .Floor {
                transform: matrix(0.96073934, 0, 0, 0.96073934, 76.11182, 90.301331);
            }
            .Window {
                transform: matrix(1.1112, 0, 0, 1.1112, 72.94, 62.718);
            }
        }

        .module_4 {
            .background {
                transform: matrix(1, 0, 0, 1, 0, 0);
            }
            .Floor {
                transform: matrix(0.96074, 0, 0, 0.96074, 219.29, -103.99);
            }
            .module_1_student {
                transform: matrix(0.55946, 0, 0, 0.55946, -477.28876, -98.452);
            }
            .person_running {
                transform: matrix(1.1564, 0, 0, 1.1564, -1437.5, -614.66);
            }
            .person_table_computer {
                transform: matrix(-1.1848, 0, 0, 1.1848, 2177.8, -222.57);
            }
        }

        .module_5 {
            .Floor {
                transform: matrix(1.1112, 0, 0, 1.1112, -3.2304, -1.9808);
            }
            .Person_woman_walking {
                transform: matrix(1.1587639, 0, 0, 1.1587639, -1889.3502, -308.6547);
            }
            .Window {
                transform: matrix(1.1112, 0, 0, 1.1112, -3.2304, -1.9808);
            }
            .Person_sitting {
                transform: matrix(0.62522, 0, 0, 0.62522, -851.69, -190.88);
            }
            .Book_Shelves {
                transform: matrix(1.1112, 0, 0, 1.1892, -4.0747, -21.938);
            }
        }

        .module_6 {
            .Holder {
                //transform: matrix(1.3374432,0,0,1.3374432,113.04691,-108.56262);
                transform: matrix(1.1720733, 0, 0, 1.1720733, 57.646325, -52.184635);
                //transform: matrix(1, 0, 0, 1, 0, 0);
            }
            .Person_woman_walking {
                transform: matrix(0.65645, 0, 0, 0.65645, -1491, 16.621);
            }
            .Person_man_sitting_computer {
                transform: matrix(0.26516, 0, 0, 0.26516, -702.26, 165.99);
            }
            .Person_sitting {
                transform: matrix(0.17153092, 0, 0, 0.17153092, 1791.5975, 281.15613);
            }
            .module_1_student {
                transform: matrix(0.1471101, 0, 0, 0.1471101, 1836.5311, 333.98954);
            }
        }
    }
}

.avoimet_custom_card:hover {
    svg {
        * {
            transition: 0.5s;
        }
        .module_1 {
            .module_1_student {
                transform: matrix(1.7683331, 0, 0, 1.7683331, -2493.0714, -320.56644);
            }
            .module_1_school {
                transform: matrix(1.0021477, 0, 0, 1.0021477, -2083.7915, -193.26892);
            }
            .module_1_skyline {
                transform: matrix(1.1, 0, 0, 1.1, 0, -10);
                //d: path("m -329.0627,113.43224 h 15.2316 V 24.226997 h 45.69016 v 45.146578 h 14.14228 V 107.9929 h 9.24691 V 45.440498 h 9.24691 V 28.034574 h 31.00436 v 16.861959 h 10.3347 v -4.895388 h 55.48147 v 36.443584 h 29.91601 V 66.109933 h 13.598767 V 54.143399 h 20.669207 v -7.615098 h 35.355771 v 9.246865 h 9.246801 V 66.653898 H -5.4205387 V 84.059815 H 26.671502 V -10.58483 H 63.65916 v 70.167605 h 36.44366 v 53.305555 h 15.2302 V -16.024076 h 44.60245 v 11.9665438 h 70.71145 V 66.109933 h 58.20102 v 37.531527 h 32.6362 v 7.61508 h 10.33472 v 37.53151 h 7.07119 v 69.07957 H -329.0627 Z");
            }
        }

        .module_2 {
            .Person_sitting {
                transform: matrix(-0.88507765, 0, 0, 0.88507765, 1446.0098, -407.8466);
            }

            .Person_computer {
                transform: matrix(1.6698556, 0, 0, 1.6698556, -2755.1498, -751.93804);
            }
            .Floor {
                transform: matrix(1, 0, 0, 1, -19.004708, -15.528149);
            }
        }

        .module_3 {
            .person_1 {
                transform: matrix(1.5271329, 0, 0, 1.5271329, -2314.314, -628.27247);
            }
            .person_2 {
                transform: matrix(-1.3520395, 0, 0, 1.3520395, 2358.6214, -302.74636);
            }
            .module_1_student {
                transform: matrix(1.28268, 0, 0, 1.28268, -1653.0446, -71.748234);
            }
            .person_4 {
                transform: matrix(1.3820003, 0, 0, 1.3820003, -2209.821, -317.7437);
            }
            .person_5 {
                transform: matrix(1.2642364, 0, 0, 1.2642364, -1994.4261, 128.78955);
            }
            .Floor {
                // transform: matrix(1.1112, 0, 0, 1.1112, 71.741, 107.7);
                transform: matrix(0.96074, 0, 0, 0.96074, 76.112, 70.448336);
            }
            .Window {
                // transform: matrix(1.630463,0,0,1.630463,58.41562,29.113317);
                transform: matrix(0.83462699, 0, 0, 0.83462699, 80.676024, 82.642758);
            }
        }

        .module_4 {
            .background {
                transform: matrix(0.87948312, 0, 0, 0.87948312, 30.841633, -11.1055);
            }
            .Floor {
                transform: matrix(0.96074, 0, 0, 0.96074, 219.28693, -121.44793);
            }
            .module_1_student {
                transform: matrix(0.55946028, 0, 0, 0.55946028, -477.28876, -105.45573);
            }
            .person_running {
                transform: matrix(1.28438, 0, 0, 1.28438, -1549.7869, -689.92106);
            }
            .person_table_computer {
                transform: matrix(-1.3738895, 0, 0, 1.3738895, 2460.3368, -268.03617);
            }
        }

        .module_5 {
            .Floor {
                transform: matrix(0.98839252, 0, 0, 0.98839252, 0.33720552, 12.032886);
            }
            .Person_woman_walking {
                transform: matrix(1.5171877, 0, 0, 1.5171877, -2531.2228, -435.32966);
            }
            .Window {
                transform: matrix(1.0035346, 0, 0, 1.0035346, -0.21896175, 11.138077);
            }
            .Person_sitting {
                transform: matrix(0.54366161, 0, 0, 0.54366161, -739.36337, -145.15033);
            }
            .Book_Shelves {
                transform: matrix(0.98839252, 0, 0, 1.057756, -0.41379001, -0.04092809);
            }
        }

        .module_6 {
            .Holder {
                transform: matrix(1.981496, 0, 0, 1.981496, 328.99226, -279.66432);
                //transform: matrix(2.3669403,0,0,2.3669403,458.12003,-402.23925);
            }
            .Person_woman_walking {
                transform: matrix(0.6564547, 0, 0, 0.6564547, -1511.7922, 16.809185);
            }
            .Person_man_sitting_computer {
                // transform: matrix(0.26515503,0,0,0.26515503,-689.83522,165.99336);
                transform: matrix(0.26516, 0, 0, 0.26516, -694.74682, 165.99);
            }
            .Person_sitting {
                transform: matrix(0.23173289, 0, 0, 0.23173289, 1712.1488, 244.07581);
            }
            .module_1_student {
                transform: matrix(0.2124564, 0, 0, 0.2124564, 1750.4617, 316.5789);
            }
            .module_1_skyline {
                transform: matrix(0.9, 0, 0, 0.9, -25, 28);
                //d: path("m -583.33862,226.3204 h 13.5607 v -79.41945 h 40.67796 v 40.19401 h 12.59087 v 34.3828 h 8.23253 v -55.69043 h 8.23252 v -15.49649 h 27.60319 v 15.0122 h 9.20099 v -4.35836 h 49.39516 v 32.44573 h 26.63423 v -9.20107 h 12.10699 v -10.6538 h 18.4018 v -6.77973 h 31.47724 v 8.23248 h 8.23244 v 9.68534 h 21.79207 v 15.49649 h 28.57154 v -84.26215 h 32.93012 v 62.47024 h 32.4458 v 47.45795 h 13.55946 V 111.06541 h 39.70957 v 10.65382 h 62.954218 v 62.47011 h 51.816352 v 33.41433 h 29.0560086 v 6.7797 h 9.2010175 v 33.41431 H 11.33965 v 61.50155 h -594.67827 z");
            }
        }
    }
}

.jumppakamu-card {
    width: 100%;
    border-color: $jumppakamu-1;

    &:hover {
        .chapter {
            color: #6666ff;
        }

        .title {
            color: #0e0fe0;
        }
    }

    .chapter {
        color: $jumppakamu-2;
        font-size: 1rem;
        line-height: 1.375rem;
        transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .title {
        color: $jumppakamu-1;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: 1.2;
        transition: color 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }

    .description {
        color: black;
        font-size: 1rem;
        line-height: 1.375rem;
    }

    ul {
        margin-bottom: 0.5rem;
    }
}
