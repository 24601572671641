// quiz fonts
.sv_p_title,
.sv_header h3 {
    font-size: 1.75rem !important;
    margin: 2rem 1rem 1rem 1rem !important;
    padding: 0 0 0.5rem 0 !important;
    font-weight: normal !important;
    color: $primary-6 !important;
    border-bottom: 1px solid rgba($primary-4, 0.8);
}

.sv_page_title {
    text-align: center;
    font-size: 2rem !important;
    margin: 2rem 1rem 1rem 1rem !important;
    padding: 0 0 0.5rem 0 !important;
    font-weight: normal !important;
    color: $primary-6 !important;
    border-bottom: 1px solid rgba($primary-4, 0.8);
}

.sv_q_title {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    font-family: inherit !important;
}

.sv_row {
    border-bottom: none !important;
}

.question_offwhite {
    .sv_body {
        background-color: rgb(244, 244, 244) !important;
    }
}

.roope_test {
    border: none;
    font-family: Dosis;

    .sv_body {
        border-top: unset !important;
        border-radius: 4px !important;
        border-left: 5px solid rgba($primary-4, 0.8) !important;
    }

    .sv_main .sv_q.sv_qstn .sv_q_title {
        text-align: left;
        border-bottom: 1px solid rgba($primary-4, 0.8);
        margin: 2rem 1rem 1rem 1rem !important;
        padding: 0 0 0.5rem 0 !important;

        span {
            font-size: 1rem !important;
            font-weight: normal !important;
            color: $primary-6 !important;
            font-family: "Open Sans";
            text-align: left;
        }
    }

    &.correct {
        .sv_body {
            border-left: 5px solid rgba(green, 0.8) !important;
        }

        .question_status {
            color: rgba(green, 0.8);
        }
    }

    &.incorrect {
        .sv_body {
            border-left: 5px solid rgba(red, 0.8) !important;
        }

        .question_status {
            color: rgba(red, 0.8);
        }
    }

    &.saved {
        .sv_body {
            border-left: 5px solid rgba($primary-6, 0.8) !important;
        }

        .question_status {
            color: rgba($primary-6, 0.8);
        }
    }

    div.sv_q_checkbox {
        span.sv_q_checkbox_control_label {
            padding-left: 1.8rem;
        }
    }
    // background-color
    div {
        background-color: inherit !important;
    }

    &.quiz_correct_order {
        .sv_main {
            .sv_container {
                .sv_body {
                    // Some bit more deeper by skipping layers
                    .sv_q {
                        .sjs-sortablejs-root {
                            .sjs-sortablejs-source {
                                border-color: rgb(0, 178, 227) !important;
                                .sjs-sortablejs-item {
                                    font-size: 1rem;
                                }
                            }
                            .sjs-sortablejs-result {
                                border-color: rgb(128, 188, 0) !important;
                                padding: 0.3rem;
                                .sjs-sortablejs-item {
                                    font-size: 1rem;
                                }
                                div:first-of-type {
                                    .sjs-sortablejs-item {
                                        margin: 0 0 5px 0 !important;
                                    }
                                }
                                div:first-of-type:last-of-type {
                                    .sjs-sortablejs-item {
                                        margin: 0 !important;
                                    }
                                }
                                div:last-of-type:not(:first-of-type) {
                                    .sjs-sortablejs-item {
                                        margin: 5px 0 0 0 !important;
                                    }
                                }
                                div:not(:first-of-type):not(:last-of-type) {
                                    .sjs-sortablejs-item {
                                        margin: 5px 0 5px 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.quiz_dropdown {
        .sv_main {
            .sv_container {
                .sv_body {
                    // Some bit more deeper by skipping layers
                    .sv_q {
                        .sv_q_dropdown_control {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.kauppakamari-question {
    border: none;
    font-family: Dosis;

    .sv_main.sv_default_css input[type="button"] {
        background-color: $kauppakamari-6 !important;
        font-family: Dosis;
        font-weight: normal !important;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .sv_body {
        border-top: unset !important;
        border-radius: 4px !important;
        border-left: 5px solid rgba($kauppakamari-4, 0.8) !important;
    }

    .sv_main .sv_q.sv_qstn .sv_q_title {
        text-align: left;
        border-bottom: 1px solid rgba($kauppakamari-4, 0.8);
        margin: 2rem 1rem 1rem 1rem !important;
        padding: 0 0 0.5rem 0 !important;

        span {
            font-size: 1rem !important;
            font-weight: 400 !important;
            color: $kauppakamari-6 !important;
            font-family: "PT Sans";
            text-align: left;
        }
    }

    &.correct {
        .sv_body {
            border-left: 5px solid rgba(green, 0.8) !important;
        }

        .question_status {
            color: rgba(green, 0.8);
        }
    }

    &.incorrect {
        .sv_body {
            border-left: 5px solid rgba(red, 0.8) !important;
        }

        .question_status {
            color: rgba(red, 0.8);
        }
    }

    &.saved {
        .sv_body {
            border-left: 5px solid rgba($kauppakamari-6, 0.8) !important;
        }

        .question_status {
            color: rgba($kauppakamari-6, 0.8);
        }
    }

    div.sv_q_checkbox {
        span.sv_q_checkbox_control_label {
            padding-left: 1.8rem;
        }
    }
    // background-color
    div {
        background-color: inherit !important;
    }

    &.quiz_correct_order {
        .sv_main {
            .sv_container {
                .sv_body {
                    // Some bit more deeper by skipping layers
                    .sv_q {
                        .sjs-sortablejs-root {
                            .sjs-sortablejs-source {
                                border-color: rgb(0, 178, 227) !important;
                                .sjs-sortablejs-item {
                                    font-size: 1rem;
                                }
                            }
                            .sjs-sortablejs-result {
                                border-color: rgb(128, 188, 0) !important;
                                padding: 0.3rem;
                                .sjs-sortablejs-item {
                                    font-size: 1rem;
                                }
                                div:first-of-type {
                                    .sjs-sortablejs-item {
                                        margin: 0 0 5px 0 !important;
                                    }
                                }
                                div:first-of-type:last-of-type {
                                    .sjs-sortablejs-item {
                                        margin: 0 !important;
                                    }
                                }
                                div:last-of-type:not(:first-of-type) {
                                    .sjs-sortablejs-item {
                                        margin: 5px 0 0 0 !important;
                                    }
                                }
                                div:not(:first-of-type):not(:last-of-type) {
                                    .sjs-sortablejs-item {
                                        margin: 5px 0 5px 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.quiz_dropdown {
        .sv_main {
            .sv_container {
                .sv_body {
                    // Some bit more deeper by skipping layers
                    .sv_q {
                        .sv_q_dropdown_control {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.cardQuestionHolder {
    position: relative;

    .registerCheck {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;

        &.visible {
            background-image: linear-gradient(to bottom, transparent, white);

            a {
                div {
                    margin: auto;
                    margin-top: 35%;
                    padding: 0.4rem;
                    background-color: rgb(0, 154, 166);
                    color: white;
                    display: table;
                    width: 90%;
                    text-align: center;
                    font-family: Dosis;
                    font-size: 2rem;
                }
            }

            a:hover {
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 576px) {
    .cardQuestionHolder {
        .registerCheck {
            div {
                font-size: 1.5rem !important;
            }
        }
    }
}

.sv_q_checkbox {
    .sv_q_checkbox_label {
        .sv_q_checkbox_control_item {
            display: none;
            &:disabled ~ .sv_q_checkbox_control_label {
                pointer-events: none;
                span {
                    color: rgb(103, 103, 103);
                }
                span::before {
                    border-color: rgb(165, 165, 165);
                }
            }
        }
        .sv_q_checkbox_control_label {
            padding-left: 2rem;
            cursor: pointer;
            span::before {
                //top: 0.25rem;
                left: 2.5rem;
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                content: "";
                background-color: #fff;
                border: rgb(0, 178, 227) solid 1px;
                border-radius: 0.3125rem;
                position: absolute;
                text-align: center;
                line-height: 1.2rem;
                margin-top: 0.2rem;
                color: white;
                transition: 0.2s;

                background-size: 80%;
                background-repeat: no-repeat;
                background-position-x: 50%;
                background-position-y: center;
            }
        }
        .correctAnswerMarker::before {
            background-image: url("../../assets/img/icon/check_green.svg");
            width: 1.25rem;
            height: 1.25rem;
            content: "";
            background-size: 80%;
            background-repeat: no-repeat;
            background-position-x: 50%;
            background-position-y: 5px;
            display: inline-block;
        }
    }

    &.checked {
        .sv_q_checkbox_label {
            .sv_q_checkbox_control_item {
                &:disabled ~ .sv_q_checkbox_control_label {
                    span::before {
                        background-color: rgb(165, 165, 165);
                    }
                }
            }
            .sv_q_checkbox_control_label {
                span::before {
                    background-image: url("../../assets/img/icon/check.svg");
                    background-color: rgb(0, 178, 227);
                }
            }
        }
    }
}

div.opiskelupaikkatesti_kysymykset {
    font-family: Dosis;
}

.sv_main {
    font-family: inherit !important;
    font-size: 0.9rem !important;
}

.sv_main .sv_q.sv_qstn {
    padding-left: 1rem !important;
    padding-bottom: 0 !important;
    overflow-y: hidden !important; // disable scroll bar popping up in sortablelist-type questions

    p,
    span {
        font-size: 1rem !important;
        font-weight: 400;
        color: black;
        margin-bottom: 0;
    }
}

.sv_p_container {
    padding-bottom: 1rem;
}

.sv_completed_page {
    padding: 1em !important;

    h3 {
        padding-bottom: 1em !important;
    }
}

// quiz buttons
.sv_main.sv_default_css input[type="button"] {
    background-color: $primary-6 !important;
    font-family: Dosis;
    font-weight: normal !important;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

// quiz borders
.sv_body {
    border-color: $primary-6 !important;
}

.sjs-sortablejs-result .sjs-sortablejs-source {
    border-color: $primary-6 !important;
}

// quiz modal window button
.sv_window {
    right: 0.5rem !important;
    bottom: 0.5rem !important;

    span span {
        padding-right: 0 !important;
    }
}

.sv_window_title {
    font-size: 1.5rem !important;
    font-weight: normal !important;
    color: $primary-6 !important;
    border-bottom: 1px solid rgba($primary-4, 0.8);
}

// quiz modal window
.survey_modal {
    pointer-events: none;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
    transition: background-color 1s ease;
    overflow-y: hidden;

    .modal_container {
        transform: scale(0);
        transition: transform 0.5s ease;
    }

    &.open {
        overflow-y: auto;
        pointer-events: unset;
        background-color: rgb(0, 0, 0); // fallback color
        background-color: rgba(0, 0, 0, 0.4);

        .modal_container {
            transform: scale(1);
        }
    }
}

// quiz modal button
#survey_modal_button {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 12rem;
    height: 3.5rem;
    border-radius: 5px;

    h3 {
        color: white;
        margin: 0 !important;
        padding: 0 !important;
    }
}

// quiz modal close button
#modal_close_button {
    position: absolute;
    z-index: 101;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

// Quiz sortable boxes
.sjs-sortablejs-result {
    div .sjs-sortablejs-item {
        background-color: rgba($primary-5, 0.7) !important;
    }
}

.sjs-sortablejs-source {
    div .sjs-sortablejs-item {
        background-color: $primary !important;
    }
}

// pretty checkbox states
.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
    background-color: #5cb85c !important;
}

.pretty input:not(:checked) ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
    background-color: red !important;
}

.pretty input:not(:checked) ~ .state.p-is-indeterminate label:after,
.pretty.p-toggle .state.p-is-indeterminate label:after {
    background-color: transparent !important;
}

.animate_progress::-webkit-progress-value {
    transition: width 1s;
}

.navbar_progress {
    * {
        color: white !important;
    }
}

.progress_container {
    .question_progress_value {
        margin: 0;
    }
}

.question_status {
    position: absolute;
    bottom: 0;
    left: 2.2rem;
    margin: 0;
}
